function centerIfOutOfView(element) {
  const rect = element.getBoundingClientRect()
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight

  // 10% margin around the viewport
  const marginFactor = 0.1

  const topVisible = rect.top >= viewportHeight * marginFactor
  const bottomVisible = rect.bottom <= viewportHeight * (1 - marginFactor)
  const leftVisible = rect.left >= viewportWidth * marginFactor
  const rightVisible = rect.right <= viewportWidth * (1 - marginFactor)

  // If any of these checks fail, the element is outside the "safe zone"
  if (!topVisible || !bottomVisible || !leftVisible || !rightVisible) {
    element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
  }
}

/**
 * Usage: attachCenterOnFocus('button, a')
 */
export function attachCenterOnFocus(selector) {
  // Grab all the elements (button or link) with the selector
  document.querySelectorAll(selector).forEach((el) => {
    el.addEventListener("focus", () => centerIfOutOfView(el))
  })
}
