import {Controller} from "stimulus"
import * as shared from "controllers-shared"
import {attachCenterOnFocus} from "util/a11y-utils"

export default class extends Controller {
  connect() {
    shared.onScroll()
    attachCenterOnFocus("button, a")
  }
}
